import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import ReactPlayer from "react-player/lazy";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

const Container = styled.div`
  padding: 0 95px;

  & .top-thumbnail-container {
    margin: 0 0 60px 0;

    & .module {
      margin: 60px 0;
    }
  }

  & .gallery-container {
    & .module {
      margin: 160px 0;

      &:first-of-type {
        margin: 60px 0 160px 0;
      }
    }
  }

  & .page-title {
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.56px;
    margin: 0;

    text-align: center;
  }

  & img,
  & iframe {
    max-height: calc(${props => props.windowHeight}px - 180px);
    max-width: 100%;
  }

  @media (max-width: 1200px) {
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    margin: 80px 0 0 0;
    padding: 0 20px;

    & .top-thumbnail-container {
      margin: 0;

      & .module {
        margin: 60px 0;
      }
    }

    & .gallery-container {
      & .module {
        margin: 60px 0;

        &:first-of-type {
          margin: 60px 0 60px 0;
        }
      }
    }
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;
  position: relative;

  & .content {
    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: contain;

    width: auto;
    height: 100%;

    margin: 0 auto;
  }
`;

const VideoWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  max-height: calc(${props => props.windowHeight}px - 180px);

  overflow: hidden;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props => props.ratio}%;
  }

  & > .player-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const PhotographyProjectContent = ({ data, setPageType, location }) => {
  useEffect(() => {
    setPageType("single-photography-project");
  }, []);

  const windowHeight = use100vh();

  let nextVideoUid = "";
  let prevVideoUid = "";
  let nextProjectPositionInReel;
  let prevProjectPositionInReel;

  if (
    location.state !== undefined &&
    location.state !== null &&
    location.state.section === `photography`
  ) {
    // Coming from Stills page
    const allStillsProjects = data.prismicPhotographers.data.projects
      .filter(project => project.project.document !== null)
      .map(project => project.project.document.uid);
    const projectPositionInStillsProjects = allStillsProjects.indexOf(
      data.prismicPhotographyProject.uid
    );
    const numberOfProjectsInStillsProjects = allStillsProjects.length;

    if (allStillsProjects !== -1) {
      // Video exists
      nextProjectPositionInReel = projectPositionInStillsProjects + 1;
      prevProjectPositionInReel = projectPositionInStillsProjects - 1;
    } else {
      nextProjectPositionInReel = false;
      prevProjectPositionInReel = false;
    }

    if (nextProjectPositionInReel >= numberOfProjectsInStillsProjects) {
      nextProjectPositionInReel = 0;
    }
    if (prevProjectPositionInReel < 0) {
      prevProjectPositionInReel = numberOfProjectsInStillsProjects - 1;
    }

    if (
      nextProjectPositionInReel !== false &&
      prevProjectPositionInReel !== false
    ) {
      nextVideoUid = allStillsProjects[nextProjectPositionInReel];
      prevVideoUid = allStillsProjects[prevProjectPositionInReel];
    } else {
      nextVideoUid = false;
      prevVideoUid = false;
    }
  }

  if (
    location.state !== undefined &&
    location.state !== null &&
    location.state.section === `filtered`
  ) {
    // Coming from a single filtered page
    const allFilteredProjects = data.allPrismicPhotographyProject.edges
      .filter(project => project.node.tags.includes(location.state.tag))
      .map(project => project.node.uid);

    const projectPositionInFilteredProjects = allFilteredProjects.indexOf(
      data.prismicPhotographyProject.uid
    );
    const numberOfProjectsInFilteredProjects = allFilteredProjects.length;

    if (allFilteredProjects !== -1) {
      // Video exists
      nextProjectPositionInReel = projectPositionInFilteredProjects + 1;
      prevProjectPositionInReel = projectPositionInFilteredProjects - 1;
    } else {
      nextProjectPositionInReel = false;
      prevProjectPositionInReel = false;
    }

    if (nextProjectPositionInReel >= numberOfProjectsInFilteredProjects) {
      nextProjectPositionInReel = 0;
    }
    if (prevProjectPositionInReel < 0) {
      prevProjectPositionInReel = numberOfProjectsInFilteredProjects - 1;
    }

    if (
      nextProjectPositionInReel !== false &&
      prevProjectPositionInReel !== false
    ) {
      nextVideoUid = allFilteredProjects[nextProjectPositionInReel];
      prevVideoUid = allFilteredProjects[prevProjectPositionInReel];
    } else {
      nextVideoUid = false;
      prevVideoUid = false;
    }
  }

  if (
    location.state === undefined ||
    location.state === null ||
    (location.state !== undefined &&
      location.state !== null &&
      location.state.section === `photographer`)
  ) {
    // Coming from a single photographers page
    const allPhotographerProjects =
      data.prismicPhotographyProject.data.photographer.document !== null
        ? data.prismicPhotographyProject.data.photographer.document.data.projects
            .filter(project => project.project.document !== null)
            .map(project => project.project.document.uid)
        : [data.prismicPhotographyProject.uid];
    const projectPositionInPhotographersProjects = allPhotographerProjects.indexOf(
      data.prismicPhotographyProject.uid
    );
    const numberOfProjectsInPhotographersProjects =
      allPhotographerProjects.length;

    if (allPhotographerProjects !== -1) {
      // Video exists
      nextProjectPositionInReel = projectPositionInPhotographersProjects + 1;
      prevProjectPositionInReel = projectPositionInPhotographersProjects - 1;
    } else {
      nextProjectPositionInReel = false;
      prevProjectPositionInReel = false;
    }

    if (nextProjectPositionInReel >= numberOfProjectsInPhotographersProjects) {
      nextProjectPositionInReel = 0;
    }
    if (prevProjectPositionInReel < 0) {
      prevProjectPositionInReel = numberOfProjectsInPhotographersProjects - 1;
    }

    if (
      nextProjectPositionInReel !== false &&
      prevProjectPositionInReel !== false
    ) {
      nextVideoUid = allPhotographerProjects[nextProjectPositionInReel];
      prevVideoUid = allPhotographerProjects[prevProjectPositionInReel];
    } else {
      nextVideoUid = false;
      prevVideoUid = false;
    }
  }

  const galleryContent = data.prismicPhotographyProject.data.body.map(
    (content, index) => {
      if (content.slice_type === "image") {
        return (
          <ImageContainer
            className="image module"
            key={`single_photography_lightbox_image_${index}`}
          >
            <div className="content">
              {content.primary.image.fluid !== null && (
                <img
                  srcSet={content.primary.image.fluid.srcSetWebp}
                  src={content.primary.image.fluid.srcWebp}
                  alt={content.primary.image.alt}
                  loading={index <= 2 ? `eager` : `lazy`}
                />
              )}
            </div>
          </ImageContainer>
        );
      }

      if (content.slice_type === "video") {
        const aspectRatio =
          (content.primary.video.height / content.primary.video.width) * 100;

        return (
          <VideoWrapper
            className="video module"
            height={content.primary.video.height}
            width={content.primary.video.width}
            ratio={aspectRatio}
            key={`single_photography_lightbox_video_${index}`}
            windowHeight={windowHeight}
          >
            <div className="player-wrapper">
              <ReactPlayer
                className="react-player"
                url={content.primary.video.embed_url}
                width="100%"
                height="100%"
                muted
                autoPlay
                loop
                config={{
                  vimeo: {
                    playerOptions: { autoplay: 1, muted: 1, loop: 1 },
                  },
                }}
              />
            </div>
          </VideoWrapper>
        );
      }
    }
  );

  const firstItem = galleryContent[0];
  const remainingItems = galleryContent.slice(1);

  return (
    <>
      <Helmet
        title={`${data.prismicPhotographyProject.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicPhotographyProject.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicPhotographyProject.data.title.text} – Park Village`,
          },
        ]}
      />
      <Container windowHeight={windowHeight}>
        <div className="top-thumbnail-container">
          {firstItem}
          <h1 className="page-title caslon-doric">
            {data.prismicPhotographyProject.data.photographer.document !==
              null && (
              <>
                <Link
                  to={`/photography/${data.prismicPhotographyProject.data.photographer.document.uid}`}
                >
                  {
                    data.prismicPhotographyProject.data.photographer.document
                      .data.name.text
                  }
                </Link>
                <span className="spacer"> ・ </span>
              </>
            )}

            {data.prismicPhotographyProject.data.title.text}
          </h1>
        </div>
        <div className="gallery-container">{remainingItems}</div>
      </Container>
    </>
  );
};

const PhotographyProject = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <PhotographyProjectContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default PhotographyProject;

export const query = graphql`
  query PhotographyProject($uid: String!) {
    allPrismicPhotographyProject {
      edges {
        node {
          uid
          tags
        }
      }
    }
    prismicPhotographers {
      data {
        projects {
          project {
            document {
              ... on PrismicPhotographyProject {
                uid
              }
            }
          }
        }
      }
    }
    prismicPhotographyProject(uid: { eq: $uid }) {
      uid
      url
      data {
        title {
          text
        }
        photographer {
          document {
            ... on PrismicPhotographer {
              id
              url
              uid
              data {
                name {
                  text
                }
                projects {
                  project {
                    document {
                      ... on PrismicPhotographyProject {
                        id
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicPhotographyProjectBodyImage {
            id
            slice_type
            primary {
              image {
                alt
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
            }
          }
          ... on PrismicPhotographyProjectBodyVideo {
            id
            slice_type
            primary {
              video {
                embed_url
                height
                width
              }
            }
          }
        }
      }
    }
  }
`;
